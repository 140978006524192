<template>
	<div class="settings-page pb-5" :class="$mq == 'sm' ? 'px-4' : 'container container-2'">
		<a-tabs size="large" :default-active-key="mainKey">
			<a-tab-pane key="1" tab="My Profile">
				<a-form class="profile mt-3" @submit="submit">
					<div :class="$mq == 'md' ? '' : 'dF'" style="gap: 3em">
						<div :style="$mq == 'md' ? 'width:100%' : 'width:25%'">
							<a-card class="profile-card">
								<img v-if="userData.avatar !== '' && userData.avatar" :src="userData.avatar"
									class="mb-3"
									style=" background-size: cover; border-radius: 50% 50% 50% 50%; width: 100px; height: 100px; object-fit: cover; " />
								<a-avatar v-else class="mb-3" :size="100" style="font-size: 50px">
									{{ getInitial(user.firstName || '', user.lastName || '') }}
								</a-avatar>
								<h5>
									{{ (user.firstName || '') + " " + (user.lastName || '') }}
								</h5>
								<div style="color: #9ea0a5">
									{{ user.email }}
								</div>
								<template slot="actions">
									<a-upload name="avatar" v-model="img" :multiple="true" :showUploadList="false"
										:beforeUpload="beforeUpload" @change="handleChange">
										<div class="footer-button">
											UPLOAD IMAGE
										</div>
									</a-upload>
									<div v-if="userData.avatar && userData.avatar !== ''" @click="changeAvatar"
										class="footer-button">
										REMOVE IMAGE
									</div>
								</template>
							</a-card>
						</div>
						<div :class="$mq == 'md' ? 'mt-3' : ''" :style="$mq == 'md' ? 'width:100%' : 'width:75%'">
							<a-card>
								<h6 class="mb-4 text-dark">
									<strong>Basic Profile</strong>
								</h6>

								<a-form-model ref="ruleForm" :model="userData" :rules="rules">
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item ref="firstName" label="First Name" prop="firstName"
												:rules="req('Please enter your First Name')">
												<a-input v-model="userData.firstName" placeholder="First Name"
													size="large" />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item ref="lastName" label="Last Name" prop="lastName"
												:rules="req('Please enter your Last Name')">
												<a-input v-model="userData.lastName" placeholder="Last Name"
													size="large" />
											</a-form-model-item>
										</a-col>
									</a-row>
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item prop="userRole" label="Your Role"
												:rules="req('Please enter your Role')">
												<a-input class="input-border-style" size="large"
													v-model="userData.userRole" />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item prop="phone" label="Business Phone Number"
												:rules="req('Please enter your Business Phone Number')">
												<a-input v-model="userData.phone" placeholder="Phone Number"
													size="large" type="number" />
											</a-form-model-item>
										</a-col>
									</a-row>
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item prop="company" label="Company Name"
												:rules="req('Please enter your Company Name')">
												<a-input v-model="userData.company" placeholder="Company"
													size="large" />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item prop="industry" label="Type Of Business"
												:rules="req('Please select your Type Of Business')">
												<a-select :default-value="userData.industry" @change="selectIndustry"
													size="large">
													<a-select-option value="Builder">
														Builder
													</a-select-option>
													<a-select-option value="Real Estate Broker">
														Real Estate Broker
													</a-select-option>
													<a-select-option value="Advertising Agency">
														Advertising Agency
													</a-select-option>
													<a-select-option value="Freelancer">
														Freelancer
													</a-select-option>
													<a-select-option value="Other">
														Other
													</a-select-option>
												</a-select>
											</a-form-model-item>
										</a-col>
									</a-row>
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item prop="address" label="Address"
												:rules="req('Please enter your Address')">
												<a-input class="input-border-style" size="large"
													v-model="userData.address" />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item prop="country" label="Country"
												:rules="req('Please select your Country')">
												<a-select show-search size="large" class="dropdown-style"
													v-model="userData.country"
													@change="userData.region = null; userData.postal = null;">
													<a-select-option v-for="country in countries" :key="country.value"
														:value="country.name">
														{{ country.name }}
													</a-select-option>
												</a-select>
											</a-form-model-item>
										</a-col>
									</a-row>
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item prop="city" label="City"
												:rules="req('Please enter your City')">
												<a-input class="input-border-style" size="large"
													v-model="userData.city" />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item prop="region" label="State/Province"
												:rules="req('Please enter your State/Province')">
												<a-input class="input-border-style" size="large"
													v-model="userData.region" />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 8">
											<a-form-model-item prop="postal" label="Zip/Postal Code"
												:rules="req('Please enter your Zip/Postal Code')">
												<a-input class="input-border-style" size="large"
													v-model="userData.postal" />
											</a-form-model-item>
										</a-col>
									</a-row>
								</a-form-model>

								<hr style="margin-left: -24px; margin-right: -24px; " />

								<h6 class="mb-4 text-dark">
									<strong>Login Settings</strong>
								</h6>

								<a-form-model ref="ruleForm2" :model="userData" :rules="rules">
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item ref="email" label="Email Address" prop="email" required>
												<a-input v-model="userData.email" placeholder="Email Address"
													size="large" disabled />
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item ref="password" label="Password" prop="password" required>
												<a-input-password v-model="userData.password"
													placeholder="Enter Your New Password" size="large" />
											</a-form-model-item>
										</a-col>
									</a-row>
									<a-alert
										message="Password should have minimum fourteen characters, at least one uppercase letter, one lowercase letter, one number and one special character from @$!%*?&."
										type="warning" show-icon />
								</a-form-model>

								<hr style="margin-left: -24px; margin-right: -24px; " />

								<h6 class="mb-4 text-dark">
									<strong>Regional Settings</strong>
								</h6>

								<a-form-model>
									<a-row :gutter="16">
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item label="Time Zone">
												<a-select v-model="regionalSettings.timeZone" size="large">
													<a-select-option v-for="zone in timeZones" :value="zone.value"
														:key="zone.value">
														{{ zone.label }}
													</a-select-option>
												</a-select>
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item label="Date Format">
												<a-select v-model="regionalSettings.dateFormat" size="large">
													<a-select-option v-for="(format, formatI) in dateFormat"
														:value="format" :key="formatI">
														{{ format }}
													</a-select-option>
												</a-select>
											</a-form-model-item>
										</a-col>
										<a-col :span="$mq == 'sm' ? 24 : 12">
											<a-form-model-item label="Time Format">
												<a-select v-model="regionalSettings.timeFormat" size="large">
													<a-select-option
														v-for="format in [{ value: 12, label: '12 hours', }, { value: 24, label: '24 hours', }]"
														:key="format.value" :value="format.value">
														{{ format.label }}
													</a-select-option>
												</a-select>
											</a-form-model-item>
										</a-col>
									</a-row>
								</a-form-model>
								<div class="dF jE w-full">
									<a-button type="primary" @click="submit" size="large">SAVE</a-button>
								</div>
							</a-card>
							<!-- <a-card class="mt-5">
								<h6 class="mb-4 text-dark">
									<strong> Notifications</strong>
								</h6>

								<div :class="$mq == 'sm' ? '' : 'dF'">
									<div class="dF" style="margin-right: 100px">
										<a-checkbox v-model="notification.email" class="mr-2"></a-checkbox>
										<div>Email</div>
									</div>
								</div>
								<div class="dF jE w-full" :class="$mq == 'sm' ? 'mt-3' : ''">
									<a-button type="primary" @click="submitNotification" size="large">SAVE</a-button>
								</div>
							</a-card> -->
						</div>
					</div>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Security">
				<div class="p-3 bg-white container-2 dF" style="gap: 50">
					<a-tabs v-model="activeKey" size="large">
						<a-tab-pane key="authenticator" tab="Authenticator" class="p-3">
							<h2>Authentication (2FA)</h2>
							<div v-if="user.provider === 'sso'" class="mt-3">
								<a-alert
									message="The 2FA authenticator app can only be used if you use password based login. If you are using SSO for logging in, you will have to setup authenticator app for 2FA on the id provider platform."
									type="warning" show-icon />
							</div>
							<div v-else>
								<div class="font-size-18 mb-3 text-dark-gray">
									By implementing 2-Step Verification, also known as two-factor authentication, you can
									enhance the security of your account in the event that your password is compromised.
								</div>
								<div v-if="user['2faEnabled']">
									<a-button type="primary" :loading="loading" @click="disableModal = true;" size="large"
										class="font-weight-semibold font-size-18">
										Disable 2FA
									</a-button>
								</div>
								<div v-else>
									<div v-if="!QRCode.url">
										<a-button type="primary" :loading="loading" @click="setup2FA" size="large"
											class="font-weight-semibold font-size-18">
											Set Up 2FA
										</a-button>
									</div>
									<div v-else>
										<hr style="margin-left: -24px; margin-right: -24px;" />
										<h3>Two-Factor Authentication (2FA)</h3>
										<div class="font-size-16 mb-3 text-dark-gray">
											<div class="text-left mt-3">
												<ul type="none">
													<li>Step1: Install any Authenticator app(Google Authenticator, Microsoft
														Authenticator, Authy etc) or browser extension</li>
													<li>Step2: Open Authenticator app or browser extension, select the '+'
														Icon.
													</li>
													<li>Step3: Select Scan a QR Code(or barcode)</li>
												</ul>
											</div>
										</div>
										<hr style="margin-left: -24px; margin-right: -24px;" />
										<h4>Scan QR Code</h4>
										<div class="ml-4 mt-3">
											<QRCodeVue :value="QRCode.url" :size="300" level="H"></QRCodeVue>
										</div>
										<div v-if="QRCode.secret">
											<hr style="margin-left: -24px; margin-right: -24px;" />
											<h4>Or Enter Code into Your App or Browser Extension</h4>
											<div class="font-size-18">
												SecretKey: <strong> {{ QRCode.secret }} </strong> (Base32 encoded)
											</div>
										</div>
										<hr style="margin-left: -24px; margin-right: -24px;" />
										<h4>Activate 2FA</h4>
										<div>
											<p class="font-size-18">
												Please input the code generated by the Authenticator app and click on the
												"Verify & Activate" button.
											</p>
											<a-input size="large" placeholder="XXXXXX" :maxLength="6" class="w-50"
												v-model="QRCode.otp" />
											<div class="dF aC mt-4" style="gap: 20px">
												<a-button @click="resetQRCodeData" type="primary" size="large" ghost>
													Close
												</a-button>
												<a-button :disabled="loading" :loading="loading" @click="verify2FA"
													type="primary" size="large" class="font-weight-semibold font-size-18">
													Verify & Activate
												</a-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</a-tab-pane>
						<a-tab-pane key="password" tab="Password" class="p-3" v-if="user.provider !== 'sso'">
							<h2>Reset Account Password</h2>
							<div class="text-dark mb-4 font-size-18">
								<span class="text-med-gray">Enter your new Password</span>
							</div>
							<a-form class="mb-4">
								<div class="mb-3">
									<a-input-password type="password" size="large" v-model="newPassword"
										placeholder="New Password" style="width: 500px;" />
								</div>
								<a-col :span="24">
									<div v-show="newPassword !== ''">
										<div v-if="getTotalPoints <= 1">
											<p style="text-align: left; margin-bottom: 0px !important;">Very weak</p>
											<div class="mb-3" style="width: 50px; border: 1px solid red;"></div>
										</div>
										<div v-else-if="getTotalPoints == 2">
											<p style="text-align: left; margin-bottom: 0px !important;">Weak</p>
											<div class="mb-3" style="width: 100px; border: 1px solid red;"></div>
										</div>
										<div v-else-if="getTotalPoints == 3">
											<p style="text-align: left; margin-bottom: 0px !important;">Medium</p>
											<div class="mb-3" style="width: 150px; border: 1px solid orange;"></div>
										</div>
										<div v-else-if="getTotalPoints == 4">
											<p style="text-align: left; margin-bottom: 0px !important;">Good</p>
											<div class="mb-3" style="width: 200px; border: 1px solid blue;"></div>
										</div>
										<div v-else>
											<p style="text-align: left; margin-bottom: 0px !important;">Strong</p>
											<div class="mb-3" style="width: 250px; border: 1px solid green;"></div>
										</div>


										<h3 class="font-size-16 text-left">Create a password that:</h3>
										<ul class="text-left"
											:style="[getPassLength ? { 'padding-inline-start': '0px !important' } : { 'padding-inline-start': '22px !important' }]">
											<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
												<a-icon v-if="getFourteenCharacter && newPassword.length > 2"
													type="check" style="color: green; padding-right: 8px;" />
												<a-icon
													v-else-if="getFourteenCharacter == false && newPassword.length > 2"
													type="close" style="color: red; padding-right: 8px;" />contains at
												least
												14 characters
											</li>
											<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
												<a-icon v-if="getLowerAndUpper && newPassword.length > 2" type="check"
													style="color: green; padding-right: 8px;" />
												<a-icon v-else-if="getLowerAndUpper == false && newPassword.length > 2"
													type="close" style="color: red; padding-right: 8px;" />contains both
												lowercase (a-z) and uppercase letters (A-Z)
											</li>
											<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
												<a-icon v-if="getNumber && newPassword.length > 2" type="check"
													style="color: green; padding-right: 8px;" />
												<a-icon v-else-if="getNumber == false && newPassword.length > 2"
													type="close" style="color: red; padding-right: 8px;" />contains at
												least
												one number (0-9)
											</li>
											<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
												<a-icon v-if="getSpecialCharacter && newPassword.length > 2"
													type="check" style="color: green; padding-right: 8px;" />
												<a-icon
													v-else-if="getSpecialCharacter == false && newPassword.length > 2"
													type="close" style="color: red; padding-right: 8px;" />contains at
												least
												one special character from @$!%*?&.
											</li>
											<li :style="[getPassLength ? { 'list-style-type': 'none' } : { '': '' }]">
												<a-icon v-if="getNameEmail &&
		newPassword.length > 2
		" type="check" style="color: green; padding-right: 8px" />
												<a-icon v-else-if="getNameEmail == false &&
		newPassword.length > 2
		" type="close" style="color: red; padding-right: 8px" />does not contain your name or email address
											</li>
										</ul>
									</div>
								</a-col>
								<div class="mb-3">
									<a-input-password type="password" size="large" v-model="confirmPassword"
										placeholder="Confirm Password" style="width: 500px;" />
								</div>
								<div v-if="confirmPassword != ''" class="text-left mb-3">
									<div v-if="confirmPassword == newPassword">
										<a-icon type="check" style="color: green; padding-right: 8px;" />Password
										matches
									</div>
									<div v-else>
										<a-icon type="close" style="color: red; padding-right: 8px;" />Password does not
										match
									</div>
								</div>
								<a-button @click="reset" :disabled="loading" :loading="loading" type="primary"
									size="large" class="text-center btn btn-primary font-size-18 mt-3"
									style="width: 500px;">
									Reset Password
								</a-button>
							</a-form>
						</a-tab-pane>
						<a-tab-pane v-if="user['2faEnabled']" key="recovery" tab="Recovery options" class="p-3">
							<h2>Recovery codes</h2>
							<div class="text-dark mb-4 font-size-18">
								<span class="text-med-gray">Recovery codes can be used to access your account in the
									event you lose
									access to your device and cannot receive two-factor authentication codes.</span>
							</div>
							<a-button v-if="!recoveryCodes.length" type="primary" :loading="loading"
								@click="generateNewCodes" size="large" class="font-weight-semibold font-size-18">
								Generate Recovery Codes
							</a-button>
							<div v-else class="w-50">
								<a-card>

									<template slot="title">
										<h4>Recovery codes</h4>
									</template>
									<a-alert class="mt-3" type="info" show-icon>

										<template slot="message">
											<strong>Keep your recovery codes as safe as your password.</strong>
											We recommend saving them
											with a password manager such as 1Password, Authy, or Keeper.
											These codes are
											the last resort for accessing your account in case
											you lose your password and second factors. If you cannot find these codes,
											you will lose access to your account.
										</template>
									</a-alert>
									<div class="dF aC jSA mt-5 font-size-18" id="codes" ref="RecoveryCodes">
										<ul>
											<li v-for="(code, index) in recoveryCodes.slice(0, halfCodeLength)"
												:key="index">{{ code
												}}</li>
										</ul>
										<ul>
											<li v-for="(code, index) in recoveryCodes.slice(halfCodeLength)"
												:key="index">{{ code }}
											</li>
										</ul>
									</div>

									<div class="dF aC jSA mt-3 mx-5">
										<a-button icon="download" size="large" @click="downloadCodes">
											Download
										</a-button>
										<a-button icon="printer" size="large" @click="printCodes">
											Print
										</a-button>
										<a-button icon="copy" size="large" @click="copyCodes">
											Copy
										</a-button>
									</div>
								</a-card>

								<div class="mt-3">
									<h5>Generate new recovery codes</h5>
									<div class="text-dark mb-4 font-size-18">
										<span class="text-med-gray">When you generate new recovery codes, you must
											download or print the new codes. Your old codes won't work anymore.</span>
									</div>
									<a-button type="primary" ghost :loading="loading" @click="generateNewCodes"
										size="large" class="font-weight-semibold font-size-18">
										Generate New Recovery Codes
									</a-button>
								</div>
							</div>
						</a-tab-pane>
					</a-tabs>
				</div>
			</a-tab-pane>
		</a-tabs>
		<a-modal v-model="disableModal" title="Disable 2FA" :width="650">
			<div v-if="mode === 'authenticator'">
				<a-form-model ref="disable" :model="disable">
					<a-form-model-item
						label="Please input the code generated by the Authenticator app and click on the 'Verify & Disable' button."
						prop="otp" :rules="req('Please enter the Verification Code')">
						<a-input size="large" placeholder="XXXXXX" :maxLength="6" class="w-50" v-model="disable.otp" />
					</a-form-model-item>
				</a-form-model>
				<div>
					<div class="font-size-16">Don't have a authenticator code?</div>
					<a-button type="link" @click="mode = 'recovery'" class="font-size-16">
						Use a recovery code
					</a-button>
				</div>
			</div>
			<div v-else>
				<a-form-model ref="disable" :model="disable">
					<a-form-model-item
						label="Please input the recovery code generated by the app and click on the 'Verify & Disable' button."
						prop="code" :rules="req('Please enter the recovery Code')">
						<a-input size="large" placeholder="XXXXX-XXXXX" :maxLength="11" class="w-50"
							v-model="disable.code" />
					</a-form-model-item>
				</a-form-model>
				<div>
					<div class="font-size-16">Don't have a recovery code?</div>
					<a-button type="link" @click="mode = 'authenticator'" class="font-size-16">
						Use your authenticator app
					</a-button>
				</div>
			</div>

			<template slot="footer">
				<a-button @click="handleDisableCancel">
					Cancel
				</a-button>
				<a-button key="submit" type="primary" :loading="loading" @click="disable2FA">
					Verify & Disable
				</a-button>
			</template>
		</a-modal>
	</div>
</template>

<script>
import { getBase64, getInitial } from "bh-mod";
import postalCodes from "postal-codes-js";
import countryData from "./countryData";
import QRCodeVue from 'qrcode.vue'

export default {
	components: { QRCodeVue },
	data: function () {
		return {
			disableModal: false,
			disable: {
				otp: '',
				code: ''
			},
			mainKey: '1',
			activeKey: 'authenticator',
			newPassword: '',
			confirmPassword: '',
			QRCode: {
				url: '',
				secret: '',
				otp: ''
			},
			mode: 'authenticator',
			recoveryCodes: [],
			img: null,
			loading: false,
			form: this.$form.createForm(this),
			formData: null,
			userData: {
				avatar: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				company: "",
				industry: "",
				userRole: "",
				address: "",
				country: "",
				city: "",
				region: "",
				postal: "",
				settings: {},
			},
			timeZones: [
				{ value: 'Etc/GMT+12', label: "Etc/GMT+12" }, //-12
				{ value: 'Pacific/Pago_Pago', label: "Pacific/Pago Pago" }, //-11
				{ value: 'Pacific/Honolulu', label: "Pacific/Honolulu" }, //-10
				{ value: 'America/Anchorage', label: "America/Anchorage" }, //-9
				{ value: 'America/Los_Angeles', label: "America/Los Angeles" }, //-8
				{ value: 'America/Denver', label: "America/Denver" }, //-7
				{ value: 'America/Chicago', label: "America/Chicago" }, //-6
				{ value: 'America/New_York', label: "America/New York" }, //-5
				{ value: 'America/Halifax', label: "America/Halifax" }, //-4
				{ value: 'America/Argentina/Buenos_Aires', label: "America/Argentina/Buenos Aires" }, //-3
				{ value: 'Etc/GMT+2', label: "Etc/GMT+2" }, //-2
				{ value: 'Atlantic/Azores', label: "Atlantic/Azores" }, //-1
				{ value: 'Etc/GMT', label: "Etc/GMT" }, //0
				{ value: 'Europe/Paris', label: "Europe/Paris" }, //+1
				{ value: 'Europe/Berlin', label: "Europe/Berlin" }, //+2
				{ value: 'Europe/Moscow', label: "Europe/Moscow" }, //+3
				{ value: 'Asia/Dubai', label: "Asia/Dubai" }, //+4
				{ value: 'Asia/Karachi', label: "Asia/Karachi" }, //+5
				{ value: 'Asia/Dhaka', label: "Asia/Dhaka" }, //+6
				{ value: 'Asia/Bangkok', label: "Asia/Bangkok" }, //+7
				{ value: 'Asia/Shanghai', label: "Asia/Shanghai" }, //+8
				{ value: 'Asia/Tokyo', label: "Asia/Tokyo" }, //+9
				{ value: 'Australia/Sydney', label: "Australia/Sydney" }, //+10
				{ value: 'Pacific/Noumea', label: "Pacific/Noumea" }, //+11
				{ value: 'Pacific/Auckland', label: "Pacific/Auckland" }, //+12
			],
			dateFormat: ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY/MM/DD"],
			regionalSettings: {
				timeZone: '',
				dateFormat: "",
				timeFormat: 0,
			},
			notification: {
				email: false,
				push: false,
			},
			countries: countryData.countryList,
		};
	},
	computed: {
		user() {
			return this.$store.state.user.user;
		},
		instances() {
			return this.$store.state.instances;
		},
		editDrawer() {
			return this.$store.state.editDrawer.show;
		},
		rules() {
			return {
				email: [{ validator: this.validateEmail, trigger: "change" }],
				postal: [
					{ validator: this.validatePostalCodes, trigger: "change" },
				],
				firstName: [
					{
						required: true,
						message: "Please enter your first name",
						trigger: "change",
					},
				],
				lastName: [
					{
						required: true,
						message: "Please enter your last name",
						trigger: "change",
					},
				],
				userRole: [
					{
						required: true,
						message: "Please enter your role",
						trigger: "change",
					},
				],
				phone: [
					{
						required: true,
						message: "Please enter your business number",
						trigger: "change",
					},
				],
				company: [
					{
						required: true,
						message: "Please enter your company name",
						trigger: "change",
					},
				],
				industry: [
					{
						required: true,
						message: "Please select your business type",
						trigger: "change",
					},
				],
				address: [
					{
						required: true,
						message: "Please enter your address",
						trigger: "change",
					},
				],
				country: [
					{
						required: true,
						message: "Please select your country",
						trigger: "change",
					},
				],
				region: [
					{
						required: true,
						message: "Please enter your region",
						trigger: "change",
					},
				],
				city: [
					{
						required: true,
						message: "Please enter your city",
						trigger: "change",
					},
				],
			};
		},

		getTotalPoints() {
			var points = 0;

			if (this.newPassword.length >= 4) points++;
			if ((this.newPassword.match(/[a-z]/)) && (this.newPassword.match(/[A-Z]/))) points++;
			if (this.newPassword.match(/\d+/)) points++;
			if (this.newPassword.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) points++;
			if (this.newPassword.length >= 14) points++;
			if (this.newPassword.length >= 16) points++;

			return points
		},
		getPassLength() {
			let passLength = false;
			if (this.newPassword.length > 2) {
				passLength = true
			}
			return passLength
		},
		getFourteenCharacter() {
			let fourteenCharacter = false;
			if (this.newPassword.length >= 14) {
				fourteenCharacter = true
			}
			return fourteenCharacter
		},
		getLowerAndUpper() {
			let lowerAndUpper = false;
			if (this.newPassword.match(/[a-z]+/) != null && this.newPassword.match(/[A-Z]+/) != null) {
				lowerAndUpper = true
			}
			return lowerAndUpper
		},
		getNumber() {
			let number = false;
			if (this.newPassword.match(/[0-9]+/) != null) {
				number = true
			}
			return number
		},
		getSpecialCharacter() {
			let specialCharacter = false;
			if (this.newPassword.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
				specialCharacter = true
			}
			return specialCharacter
		},

		getNameEmail() {
			let noNameEmail = true;
			if (
				this.user.firstName != "" &&
				this.user.lastName != "" &&
				this.user.email != ""
			) {
				if (
					this.newPassword.match(new RegExp(this.user.firstName, "i")) != null ||
					this.newPassword.match(new RegExp(this.user.lastName, "i")) != null ||
					this.newPassword.match(new RegExp(this.user.email, "i")) != null
				) {
					noNameEmail = false;
				}
			}
			return noNameEmail;
		},

		halfCodeLength() {
			if (this.recoveryCodes.length) {
				let length = this.recoveryCodes.length
				return Math.ceil(length / 2)
			}
			return 0;
		}
	},
	methods: {
		req: (msg) => ({ required: true, message: msg }),

		validateEmail(rule, value, callback) {
			if (value === "") {
				callback(new Error("Please enter an email"));
			} else {
				const result =
					/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
						value
					);
				if (result) {
					callback();
				} else {
					callback(new Error("That's not a valid email address."));
				}
			}
		},

		validatePostalCodes(rule, value, callback) {
			if (value === "") {
				callback(new Error("Please enter a Zip/Postal Code"));
			} else {
				if (!this.userData.country) {
					callback(new Error("Please select a country first"));
				}
				const countryCode =
					countryData.countries[this.userData.country];
				const result = postalCodes.validate(countryCode, value);
				if (result === true) {
					callback();
				} else {
					callback(new Error(result));
				}
			}
		},

		submitNotification() {
			let regional = {};
			let objectSettings = JSON.parse(
				JSON.stringify(this.user.settings)
			);
			if (
				this.user &&
				this.user.settings &&
				this.user.settings.options &&
				this.user.settings.options.regional
			) {
				regional = this.user.settings.options.regional;
			}
			objectSettings.regional = regional;
			objectSettings.notification = this.notification;
			this.$api
				.post("/settings", { options: objectSettings })
				.then(({ data }) => {
					let user = JSON.parse(JSON.stringify(this.user));
					user.settings = data;
					this.$store.commit("UPDATE_USER", user);
					this.$notification["success"]({
						message: "Updated",
						description:
							"You have successfully Updated your Profile!",
					});
				}).catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
		},
		selectIndustry(value) {
			this.userData.industry = value;
		},
		getInitial,
		changeAvatar() {
			this.userData.avatar = "";
		},
		async submit(e) {
			e.preventDefault();
			let formData = this.formData;
			let avatarUrl = false;
			let avatarId = false;
			if (formData) {
				let okay = false;
				for (var [key, value] of formData.entries()) {
					if (value) okay = true;
				}
				if (okay) {
					try {
						let url = `/upload`;
						let { data } = await this.$api.post(url, formData, {
							headers: { "Content-Type": "multipart/form-data" },
						});
						if (Array.isArray(data) && data.length) {
							data = data[0];
							if (data.url) {
								avatarUrl = data.url;
								avatarId = data.id;
							}
						}
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}
				}
			}
			let sendObj = JSON.parse(JSON.stringify(this.userData));

			if (avatarUrl) sendObj.avatar = avatarUrl;
			if (avatarId) sendObj.avatarId = avatarId;

			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					let regional = {};
					let notification = {};
					let objectSettings = JSON.parse(
						JSON.stringify(this.user.settings)
					);
					if (
						this.user &&
						this.user.settings &&
						this.user.settings.options &&
						this.user.settings.options.notification
					) {
						notification =
							this.user.settings.options.notification;
					}
					regional = this.regionalSettings;
					let successSettings = false;
					let userSettings = {};
					objectSettings.notification = notification;
					objectSettings.regional = regional;
					delete objectSettings.options
					this.$api
						.post("/settings", { options: objectSettings })
						.then(({ data }) => {
							successSettings = true;
							userSettings = data;
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						});
					let url = `/users/${this.user.id}`;
					this.$api
						.put(url, sendObj)
						.then(({ data }) => {
							if (data.id) {
								if (successSettings) {
									data.settings = userSettings;
								}
								this.formData = null;
								this.$store.commit("UPDATE_USER", data);
								let {
									firstName,
									lastName,
									email,
									avatar,
									phone,
									company,
									industry,
									userRole,
									address,
									country,
									city,
									region,
									postal,
									settings,
								} = this.user;
								this.userData = {
									firstName,
									lastName,
									email,
									avatar,
									phone,
									company,
									industry,
									userRole,
									address,
									country,
									city,
									region,
									postal,
									settings,
								};
								this.$notification["success"]({
									message: "Updated",
									description:
										"You have successfully Updated your Profile!",
								});
							}
						})
						.catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
							if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.length) {
								let error = err.response.data.errors.map((e) => {
									return `${e.path && e.path[0]}: ${e.message}`
								})
								this.$message.error(error.join(', '))
							}
						});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		handleChange(info) {
			this.formData = new FormData();

			this.formData.append("files", info.file, info.file.name);

			// Get this url from response in real world.
			getBase64(info.file, (imageUrl) => {
				this.userData.avatar = imageUrl;
				this.loading = false;
			});
		},
		beforeUpload(file) {
			const isJPG = file.type === "image/jpeg";
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
				this.$message.error("Image must smaller than 2MB!");
				return false;
			}
			return false;
		},

		handleDisableCancel() {
			this.$refs.disable.clearValidate();
			this.$refs.disable.resetFields();
			this.disableModal = false;
		},

		async disable2FA() {
			this.$refs.disable.validate(async (validForm) => {
				if (validForm) {
					this.loading = true;
					try {
						let payload = { otp: this.disable.otp }
						if (this.mode === 'recovery') {
							payload = { recovery_code: this.disable.code }
						}

						await this.$api.post('/2fa/disable', payload);
						this.disableModal = false;
						let user = JSON.parse(JSON.stringify(this.user));
						user['2faEnabled'] = false;
						this.$store.commit("UPDATE_USER", user);
						this.$message.success('Successfully disabled the 2FA on your account.')
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, `An error occurred while disabling 2FA. Please try again.`))
						}
					}
					this.loading = false;
				}
			})
		},

		async setup2FA() {
			this.loading = true;
			try {
				const { data } = await this.$api.post('/2fa/generate');
				this.QRCode.url = data.otpauth_url;
				this.QRCode.secret = data.base32_secret;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while setting up 2FA. Please try again.`))
				}
			}
			this.loading = false;
		},

		async verify2FA() {
			this.loading = true;
			try {
				await this.$api.post('/2fa/verify', { otp: this.QRCode.otp });
				let user = JSON.parse(JSON.stringify(this.user));
				user['2faEnabled'] = true;
				this.$store.commit("UPDATE_USER", user);
				this.resetQRCodeData();
				this.$message.success('Successfully setup the 2FA on your account.')
				this.activeKey = 'recovery'
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while verifying 2FA. Please try again.`))
				}
			}
			this.loading = false;
		},

		resetQRCodeData() {
			this.QRCode = {
				url: '',
				secret: '',
				otp: ''
			}
		},

		reset() {
			if (this.newPassword.match(/[0-9]+/) == null) return this.$message.error('Password must contain a number')
			if (this.newPassword.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) == null) return this.$message.error('Password must contain a special character from @$!%*?&.')
			if (this.newPassword.match(/[a-z]+/) == null) return this.$message.error('Password must contain a lowercase letter')
			if (this.newPassword.match(/[A-Z]+/) == null) return this.$message.error('Password must contain an uppercase letter')
			if (this.newPassword.length < 14) return this.$message.error('Password must be atleast 14 characters in length')
			if (!this.getNameEmail) return this.$message.error('Password must not contain your name or email address')
			if (this.newPassword !== this.confirmPassword) return this.$message.error('Confirm Password does not match')

			this.loading = true;
			this.$api.put(`/users/${this.user.id}`, {
				password: this.newPassword
			}).then(() => {
				this.loading = false;
				this.newPassword = '';
				this.confirmPassword = '';
				this.$message.success('Account password reset successfully.')
			}).catch((err) => {
				this.loading = false;
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Something went wrong. If you are still unable to access your account please send an email to our Customer Care team and we would be happy to help you.'))
				}
			})
		},

		async getRecoveryCodes() {
			this.loading = true;
			try {
				const { data } = await this.$api.get('/recovery-codes');
				this.recoveryCodes = JSON.parse(JSON.stringify(data.recovery_codes));
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while getting recovery codes. Please try again.`))
				}
			}
			this.loading = false;
		},

		async generateNewCodes() {
			this.loading = true;
			try {
				const { data } = await this.$api.post('/recovery-codes/generate');
				this.recoveryCodes = JSON.parse(JSON.stringify(data.recovery_codes));
				this.$message.success('Successfully generated new recovery codes.')
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while generating new recovery codes. Please try again.`))
				}
			}
			this.loading = false;
		},

		downloadCodes() {
			// Create a link element to download the CSV file
			const url = window.URL.createObjectURL(new Blob([this.recoveryCodes.join('\n')]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'Bildhive Recovery Codes.txt');
			document.body.appendChild(link);
			link.click();
		},

		printCodes() {
			// Create a new window and document
			const printWindow = window.open('', '_blank');
			const printDocument = printWindow.document;

			// Write the HTML content to the print window's document
			printDocument.write('<html><head><title>Print</title>');
			printDocument.write('</head><body>');
			printDocument.write('<div>' + this.$refs.RecoveryCodes.innerHTML + '</div>');
			printDocument.write('</body></html>');
			printDocument.close();

			// Trigger printing using CSS media queries
			printWindow.print();
			return;
		},

		copyCodes() {
			navigator.clipboard.writeText(this.recoveryCodes.join('\n'));
			this.$message.success("Recovery codes copied Successfully!");
		},
	},
	created() {
		let {
			firstName,
			lastName,
			email,
			avatar,
			phone,
			company,
			industry,
			userRole,
			address,
			country,
			city,
			region,
			postal,
			settings,
		} = this.user;
		this.userData = {
			firstName,
			lastName,
			email,
			avatar,
			phone,
			company,
			industry,
			userRole,
			address,
			country,
			city,
			region,
			postal,
			settings,
		};
		if (
			this.user.settings == null ||
			!this.user.settings ||
			!this.user.settings.options ||
			!this.user.settings.options.regional ||
			Object.keys(this.user.settings.options.regional).length == 0 ||
			typeof this.user.settings.options.regional.timeZone != 'string'
		) {
			this.regionalSettings.timeZone = 'America/New_York';
			this.regionalSettings.dateFormat = "DD/MM/YYYY";
			this.regionalSettings.timeFormat = 12;
		} else if (
			this.user.settings &&
			this.user.settings.options &&
			this.user.settings.options.regional &&
			Object.keys(this.user.settings.options.regional).length != 0
		) {
			let settings = this.user.settings;
			this.regionalSettings.timeZone = settings.options.regional.timeZone;
			this.regionalSettings.dateFormat =
				settings.options.regional.dateFormat;
			this.regionalSettings.timeFormat =
				settings.options.regional.timeFormat;
		}
		if (
			this.user.settings == null ||
			!this.user.settings ||
			!this.user.settings.options ||
			!this.user.settings.options.notification ||
			Object.keys(this.user.settings.options.notification).length ==
			0
		) {
			this.notification.email = true;
			this.notification.push = false;
		} else if (
			this.user.settings &&
			this.user.settings.options &&
			this.user.settings.options.notification &&
			Object.keys(this.user.settings.options.notification).length !=
			0
		) {
			this.notification.email =
				this.user.settings.options.notification.email;
			this.notification.push =
				this.user.settings.options.notification.push;
		}

		if (window.location.href.includes('settings/accounts?password-reset')) {
			this.$message.error('Password is expired. Please reset your password first.')
			this.mainKey = '2'
			this.activeKey = 'password'
		}

		if (window.location.href.includes('settings/accounts?2FA')) {
			this.mainKey = '2'
			this.activeKey = 'authenticator'
		}

		this.getRecoveryCodes();
	},
};
</script>

<style lang="scss">
.container-2 {
	max-width: 100vw;
	margin-left: 0;
	margin-right: 0;
	padding-right: 25px;
	padding-left: 25px;
}

.profile-card {
	text-align: center;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
	text-align: left !important;
}

.ant-tabs-nav .ant-tabs-tab:last-child {
	margin-right: 1.33rem !important;
}

.ant-card-actions>li:not(:last-child) {
	border-right: none;
}

.footer-button {
	color: var(--black);
	transition: color 0.2s ease-in;
}

.footer-button:hover {
	color: var(--orange);
	transition: color 0.2s ease-in;
}

.ant-form-item-label>label::after {
	content: "";
}

.settings-page {
	background-color: #f7f5f9;
}
</style>
